import React, { useState } from 'react';
import { LightMode, DarkMode } from '@mui/icons-material';
import logo from './evo_dig_logo.png';
import './App.css';
import SamplesRequest from './pages/DeviceSamples/SampleRequestForm';
import { Box, Divider } from '@mui/material';

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`App ${isDarkMode ? 'dark' : 'light'}`}>
      <header className="App-header">
      <script src="https://www.google.com/recaptcha/enterprise.js?render=6Lc7tWQqAAAAAH3zOYmxCEKDOEZkRmS5nKE2ElU1"></script>
        <img src={logo} className="App-logo" alt="logo" />
        <div className="theme-toggle" onClick={toggleTheme}>
          {isDarkMode ? <LightMode /> : <DarkMode />}
        </div>
        <h1 className="header-title">Broadband Samples Request</h1>
        <p className="header-subtitle">Use this form to request customer samples from the support and product teams.</p>
      </header>
      <main className="App-content">
        <Divider />
        <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '100vh', // Ensures the Box takes up the full height of the viewport
              padding: 2,
            }}>
          <SamplesRequest />
        </Box>
      </main>
    </div>
  );
}

export default App;
